import * as React from "react"
import { graphql, Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import Seo from "../components/seo"
import Header from "../components/header"
import Footer from "../components/footer"
import ReactCompareImage from "react-compare-image"
import image1 from "../images/image1.png"
import image2 from "../images/image2.png"
import mimage1 from "../images/mimage1.png"
import mimage2 from "../images/mimage2.png"
import {
  ReactCompareSlider,
  ReactCompareSliderImage,
} from "react-compare-slider"
import Blog from "../components/blog"

const description = "tio helps D2C brands to become digitally fluent by making their digital workflows flawless."

const IndexPage = ({ data }) => {
  const posts = data.allWpPost.edges

  return (
    <>
      <Seo title="Home" description={description} canonical="https://tio.ist/d2c/" />
      <div className="bg-gradient-to-b from-darkBlue to-blue">
        <Header siteTitle={`Tio`} />
        <div className="flex items-center justify-center flex-wrap lg:p-20 container mx-auto text-center">
          <h1 className="text-white text-center px-5 py-10 lg:p-10 block text-4xl font-bold lg:text-8xl">
            Make your business tio-powered
          </h1>
          <h3 className="text-white text-center px-5 pb-10 lg:py-10 w-8/12 lg:w-6/12 block text-l lg:text-2xl font-thin">
            We help D2C brands and scale-ups to become <br />
            <b className="font-bold">digitally fluent</b> in their e-commerce,
            retail, marketing, accounting and production workflows.
          </h3>
        </div>
      </div>
      <div className="absolute top-0 hidden lg:block">
        <svg
          width="1310"
          height="6377"
          viewBox="0 0 1310 6377"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            className="path z-1 "
            stroke="#FEE52A"
            pathLength="1"
            strokeWidth="3"
            d="M932.516 -138C839.516 169 1083.92 119.4 1129.52 309C1186.52 546 1025.02 674.5 967.522 641.5C910.022 608.5 965.022 490.5 1120.52 546C1276.02 601.5 1312.02 679.5 1307.02 754C1302.02 828.5 1160.95 805 1146.02 878C1137.02 922 1205.02 997 1243.02 1044C1281.02 1091 1298.1 1164.39 1104.52 1301C955.016 1406.5 923.022 1558 1020.02 1601.5C1117.02 1645 1200.52 1662 1202.02 1790C1203.52 1918 1173.01 1913 1160.52 1986.5C1144.26 2082.14 1268.31 2175.5 1291.02 2114C1313.72 2052.5 1136.46 2067 1068.52 2119C1000.58 2171 997.516 2059.5 930.517 2056C863.517 2052.5 830.011 2167.5 954.516 2233C1178.61 2350.89 948.511 2472.5 1114.02 2467C1224.05 2463.34 1025.52 2626.5 1160.52 2751.5C1295.52 2876.5 1268.32 2962.2 1191.52 3057C1095.52 3175.5 1115.02 3437 1191.52 3387C1253.46 3346.52 1216.52 3236.5 1076.02 3319.5C935.516 3402.5 1191.52 3473 1191.52 3583.5C1191.52 3694 1023.96 3531 1001.46 3755C978.958 3979 602.458 3787 504.958 3775C407.458 3763 205.958 3887 108.458 3820C10.958 3753 -2.54199 3662 32.958 3618C68.458 3574 157.506 3551.5 214.982 3600C272.458 3648.5 366.434 3785 190.958 4062.5C15.4818 4340 271.016 5189 47.5162 5056C-57.8422 4993.3 10.458 4723.5 349.958 5056C552.446 5254.31 982.458 5208 1024.96 5124.5C1067.46 5041 1011.46 4915 971.458 4966.5C931.458 5018 1126.46 5082.5 1195.46 5223C1264.46 5363.5 1226.19 5493 1209.19 5583C1192.19 5673 1136.96 5786 1047.46 5779C957.958 5772 928.358 5709.5 931.958 5661.5C936.458 5601.5 990.559 5548.5 1090.96 5583C1191.36 5617.5 1236.46 5712 1222.46 5832C1208.46 5952 970.958 5864.29 970.958 6209C970.958 6489.5 637.016 6218.5 637.016 6376.5"
          />
        </svg>
      </div>
      <div>
        <div className="hidden lg:block py-10">
          <ReactCompareImage
            leftImage={image1}
            rightImage={image2}
            hover
            sliderLineWidth={0}
            handleSize="300"
            handle={
              <button type="button">
                <StaticImage
                  imgStyle={{ objectFit: "cover" }}
                  src="../images/led.png"
                  alt="Before / After tio"
                />
              </button>
            }
          />
        </div>
        <div className="max-w-sm mx-auto lg:hidden">
          <ReactCompareSlider
            portrait
            itemOne={<ReactCompareSliderImage src={mimage1} alt="After" />}
            itemTwo={<ReactCompareSliderImage src={mimage2} alt="Before" />}
            onlyHandleDraggable
            handle={
              <button type="button">
                <StaticImage
                  className="max-w-sm"
                  src="../images/handle2.png"
                  alt="Before / After tio"
                />
              </button>
            }
          />
        </div>
        <p className="mx-auto w-full lg:w-8/12 p-10 text-center text-blue font-bold text-3xl z-10 relative  lg:text-5xl">
          We make your tangled workflow flawless + all digital with our digital{" "}
          <Link className="text-pink" to="/our-products">
            products
          </Link>{" "}
          and{" "}
          <Link className="text-yellow" to="/services">
            services
          </Link>
          .
        </p>
        <div className="mx-auto p-20 text-center ">
          <span className="p-10">
            <StaticImage
              className="bounce"
              width={30}
              src="../images/arrow.png"
              alt="Before / After tio"
            />
          </span>
          <h3 className="text-blue text-xl p-10 font-thin">
            A BETTER WORKFLOW IS POSSIBLE
          </h3>
        </div>
      </div>
      <div className="bg-blue text-white py-20">
        <div className="relative">
          <div className="absolute z-1 -top-20 lg:top-0 -left-10 lg:left-10">
            <StaticImage
              className=""
              src="../images/tell-us-more.png"
              width={200}
              alt="tio"
            />
          </div>

          <div className="absolute z-1 -bottom-10  lg:bottom-10 right-10">
            <StaticImage
              className="bounce"
              src="../images/click.png"
              width={100}
              alt="tio"
            />
          </div>

          <div className="container text-center mx-auto lg:w-2/4 py-10 lg:p-10">
            <h2 className="text-5xl p-10 font-bold">
              First, we listen to you.
            </h2>

            <p className="p-10 text-xl lg:text-3xl font-thin">
              We don’t believe in “one software fits all” type of digital
              solutions. Your business is{" "}
              <b className="font-extrabold">unique</b> so your digital
              experience should be.
            </p>
            <p className="p-10 text-xl lg:text-3xl font-thin">
              Your workflow should be listened to, observed and evaluated before
              suggesting any <b className="font-extrabold">digital solution</b>{" "}
              for your business.
            </p>
          </div>
        </div>
      </div>
      <div className="text-black  py-20">
        <div className="relative">
          <div className="absolute z-1 -top-20 lg:top-0 left-10">
            <StaticImage width={200} src="../images/instore.png" alt="tio" />
          </div>

          <div className="container text-center mx-auto lg:w-2/4 py-10 p-10">
            <h2 className="text-3xl lg:text-5xl p-10 text-blue font-bold">
              Digitally fluent how?{" "}
            </h2>

            <p className="p-10 text-xl lg:text-3xl font-thin">
              Book a <b className="font-extrabold">free consultancy meeting</b>{" "}
              with us now to start our digital partnership and we'll tell you
              how.
            </p>
            <p className="p-10 text-xl lg:text-3xl font-thin">
              This in-store/online meeting is to understand you and your
              business better and also to demonstrate how we work with our
              digital partners to you.
            </p>
            <Link
              to="/digital-consultancy"
              className="bg-blue text-white px-10 py-2 text-l"
            >
              Book Now
            </Link>
            <div className="mx-auto p-20 text-center ">
              <span className="p-10">
                <StaticImage
                  className="bounce"
                  width={30}
                  src="../images/arrow.png"
                  alt="Before / After tio"
                />
              </span>

              <h3 className="text-blue text-xl py-10 lg:p-10 font-thin">
                WHAT'S NEXT?
              </h3>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-lightBlue text-black  py-20">
        <div className="relative">
          <div className="absolute z-1 -top-32 lg:-top-10 left-20">
            <StaticImage
              className=""
              width={150}
              src="../images/best-pdf.png"
              alt="tio"
            />
          </div>
          <div className="container text-center mx-auto lg:w-2/4 py-10 lg:p-10">
            <h2 className="text-5xl p-10 font-bold text-blue">
              Then, we make a plan.
            </h2>

            <p className="p-10 text-xl lg:text-3xl font-thin">
              After our first meeting, we analyze everything you provide us
              about your business.
            </p>
            <p className="p-10 text-xl lg:text-3xl font-thin">
              You’ll receive a juicy PDF containing:
            </p>
            <ul className="text-l pb-10 lg:text-xl font-thin list-disc list-inside text-left w-full px-10 mx-auto">
              <li> the notes of our meeting, </li>
              <li>
                {" "}
                information about:
                <p>our products and services </p>
                <p>any other digital product </p>
                <p>which can be useful to make your workflow flawless.</p>
              </li>
            </ul>
            <Link
              to="/digital-consultancy"
              className="bg-white text-blue px-10 py-2 text-l"
            >
              Take me to booking ↗
            </Link>
          </div>
        </div>
      </div>
      <div className="bg-white text-black py-20">
        <div className="relative">
          <div className="mx-auto p-10 text-center ">
            <span className="p-10">
              <StaticImage
                className="bounce"
                width={30}
                src="../images/arrow.png"
                alt="Before / After tio"
              />
            </span>

            <h3 className="text-blue text-xl p-10 font-thin"> ALMOST THERE </h3>
          </div>
          <div className="absolute z-1 -top-32 lg:-top-20 right-10">
            <StaticImage width={200} src="../images/loading.png" alt="tio" />
          </div>
          <div className="container text-center mx-auto lg:w-2/4 p-10">
            <h2 className="text-5xl pb-10 font-bold text-blue">
              Time to realize your dream workflow!
            </h2>

            <p className="p-10 text-xl lg:text-3xl font-thin">
              We get to work immediately after we become digital partners. We
              apply our solutions with a step-by-step methodology. (We know it’s
              impossible otherwise).
            </p>
            <p className="p-10 text-xl lg:text-3xl font-thin">
              We also provide you in store education of the products to your
              team and guide you in every step of the adaptation process.
            </p>
            <Link to="/" className="text-blue text-l">
              READY TO START?
            </Link>
          </div>
        </div>
      </div>

      <div className="ticker-wrap">
        <Link className="ticker z-10" to="/">
          <div className="ticker-item">
            <p className="text-white" to="/digital-consultancy">
              Book a Free Consultation
            </p>
            <StaticImage height={30} src="../images/up-arrow.png" alt="tio" />
          </div>
          <div className="ticker-item">
            <p className="text-white" to="/digital-consultancy">
              Book a Free Consultation
            </p>
            <StaticImage height={30} src="../images/up-arrow.png" alt="tio" />
          </div>
          <div className="ticker-item">
            <p className="text-white" to="/digital-consultancy">
              Book a Free Consultation
            </p>
            <StaticImage height={30} src="../images/up-arrow.png" alt="tio" />
          </div>
          <div className="ticker-item">
            <p className="text-white" to="/digital-consultancy">
              Book a Free Consultation
            </p>
            <StaticImage height={30} src="../images/up-arrow.png" alt="tio" />
          </div>
          <div className="ticker-item">
            <p className="text-white" to="/digital-consultancy">
              Book a Free Consultation
            </p>
            <StaticImage height={30} src="../images/up-arrow.png" alt="tio" />
          </div>
        </Link>
        <Link className="ticker z-10" to="/digital-consultancy">
          <div className="ticker-item">
            <p className="text-white" to="/digital-consultancy">
              Book a Free Consultation
            </p>
            <StaticImage height={30} src="../images/up-arrow.png" alt="tio" />
          </div>
          <div className="ticker-item">
            <p className="text-white" to="/digital-consultancy">
              Book a Free Consultation
            </p>
            <StaticImage height={30} src="../images/up-arrow.png" alt="tio" />
          </div>
          <div className="ticker-item">
            <p className="text-white" to="/digital-consultancy">
              Book a Free Consultation
            </p>
            <StaticImage height={30} src="../images/up-arrow.png" alt="tio" />
          </div>
          <div className="ticker-item">
            <p className="text-white" to="/digital-consultancy">
              Book a Free Consultation
            </p>
            <StaticImage height={30} src="../images/up-arrow.png" alt="tio" />
          </div>
          <div className="ticker-item">
            <p className="text-white" to="/digital-consultancy">
              Book a Free Consultation
            </p>
            <StaticImage height={30} src="../images/up-arrow.png" alt="tio" />
          </div>
        </Link>
      </div>

      <div className="p-10 lg:p-20 relative">
        <div className="absolute z-10 -top-5 right-10 lg:right-20">
          <StaticImage
            className="bounce"
            width={100}
            src="../images/cursor.png"
            alt="tio"
          />
        </div>
        <div className="w-full">
          <h3 className="text-center large:pl-20 text-xl">Trusted by</h3>
          <ul className="flex mx-auto max-w-screen-xl no-scrollbar items-center overflow-x-auto">
            <li className="w-96 py-10 px-10 text-center">
              <StaticImage
                className="mx-auto block w-24 "
                width={100}
                src="../images/c1.png"
                alt="tio"
              />
            </li>
            <li className="w-96 py-10 px-10 text-center">
              <StaticImage
                className="mx-auto block w-24 "
                width={100}
                src="../images/c2.png"
                alt="tio"
              />
            </li>
            <li className="w-96 py-10 px-10 text-center">
              <StaticImage
                className="mx-auto block w-24 "
                width={100}
                src="../images/c3.png"
                alt="tio"
              />
            </li>
            <li className="w-96 py-10 px-10 text-center">
              <StaticImage
                className="mx-auto block w-24 "
                width={100}
                src="../images/c4.png"
                alt="tio"
              />
            </li>
            <li className="w-96 py-10 px-10 text-center">
              <StaticImage
                className="mx-auto block w-24 "
                width={100}
                src="../images/c5.png"
                alt="tio"
              />
            </li>
            <li className="w-96 py-10 px-10 text-center">
              <StaticImage
                className="mx-auto block w-24 "
                width={100}
                src="../images/c6.png"
                alt="tio"
              />
            </li>
            <li className="w-96 py-10 px-10 text-center">
              <StaticImage
                className="mx-auto block w-24 "
                width={100}
                src="../images/c7.png"
                alt="tio"
              />
            </li>
            <li className="w-96 py-10 px-10 text-center">
              <StaticImage
                className="mx-auto block w-24 "
                width={100}
                src="../images/c8.png"
                alt="tio"
              />
            </li>
            <li className="w-96 py-10 px-10 text-center">
              <StaticImage
                className="mx-auto block w-24 "
                width={100}
                src="../images/c9.png"
                alt="tio"
              />
            </li>
            <li className="w-96 py-10 px-10 text-center">
              <StaticImage
                className="mx-auto block w-24 "
                width={100}
                src="../images/c10.png"
                alt="tio"
              />
            </li>
          </ul>
        </div>
      </div>

      <div className="bg-lightBlue py-10 lg:p-10">
        <div className="container text-center mx-auto py-10 lg:p-10 relative">
          <div
            className="absolute z-10 inset-x-0 inset-y-1/4"
            style={{
              width: "150px",
              left: "50%",
              transform: "translateX(-50%)",
            }}
          >
            <StaticImage width={150} src="../images/coffee.png" alt="tio" />
          </div>
          <div className="flex flex-wrap w-full justify-between text-blue items-center">
            <h2 className="text-5xl text-left p-10 font-bold ">
              Our latest news, stories and insights.
            </h2>
            <Link to="/blog"
            className="border-b-2 mx-10 large:m-0 font-medium border-blue md:block "
            >
              Read All ↗
            </Link>
          </div>
          <div className="lg:flex">
            {posts.slice(0, 2).map(post => {
              const featuredImage = {
                data: post.node.featuredImage?.node?.localFile?.childImageSharp
                  ?.gatsbyImageData,
                alt: post.node.featuredImage?.node?.alt || ``,
              }

              return (
                <Blog
                  key={post.node.id}
                  title={post.node.title}
                  uri={post.node.uri}
                  featuredImage={featuredImage}
                />
              )
            })}
          </div>
        </div>
      </div>
      <Footer />
    </>
  )
}
export default IndexPage

export const pageQuery = graphql`
  {
    allWpPost(
      filter: { language: { slug: { eq: "en" } } }
      sort: { order: DESC, fields: [date] }
    ) {
      edges {
        node {
          id
          uri
          excerpt
          content
          title
          author {
            node {
              id
              avatar {
                url
              }
              name
              uri
              description
            }
          }
          date(formatString: "MMMM DD, YYYY")
          featuredImage {
            node {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    quality: 100
                    placeholder: TRACED_SVG
                    layout: FULL_WIDTH
                  )
                }
              }
            }
          }
        }
      }
    }
  }
  `